import * as StateMachine from 'javascript-state-machine';
import {Event} from '../enums/Event';
import {AnalyticsStateMachineOptions} from '../types/AnalyticsStateMachineOptions';
import {StateMachineCallbacks} from '../types/StateMachineCallbacks';
import {EventDebugging} from '../utils/EventDebugging';
import {logger, padRight} from '../utils/Logger';
import {AnalyticsStateMachine} from './AnalyticsStateMachine';

enum State {
  SETUP = 'SETUP',
  STARTUP = 'STARTUP',
  READY = 'READY',
  PLAYING = 'PLAYING',
  REBUFFERING = 'REBUFFERING',
  PAUSE = 'PAUSE',
  QUALITYCHANGE = 'QUALITYCHANGE',
  PAUSED_SEEKING = 'PAUSED_SEEKING',
  PLAY_SEEKING = 'PLAY_SEEKING',
  END_PLAY_SEEKING = 'END_PLAY_SEEKING',
  QUALITYCHANGE_PAUSE = 'QUALITYCHANGE_PAUSE',
  QUALITYCHANGE_REBUFFERING = 'QUALITYCHANGE_REBUFFERING',
  END = 'END',
  ERROR = 'ERROR',
  AD = 'AD',
  MUTING_READY = 'MUTING_READY',
  MUTING_PLAY = 'MUTING_PLAY',
  MUTING_PAUSE = 'MUTING_PAUSE',
  CASTING = 'CASTING',
  SOURCE_CHANGING = 'SOURCE_CHANGING',
  SUBTITLE_CHANGING = 'SUBTITLE_CHANGING',
  AUDIOTRACK_CHANGING = 'AUDIOTRACK_CHANGING',
  CUSTOMDATACHANGE = 'CUSTOMDATACHANGE',
}

export class Bitmovin7AnalyticsStateMachine extends AnalyticsStateMachine {
  public static PAUSE_SEEK_DELAY = 200;
  public static SEEKED_PAUSE_DELAY = 300;

  private debuggingStates: EventDebugging[] = [];
  private enabledDebugging = false;

  private pausedTimestamp: any;
  private seekTimestamp: number;
  private seekedTimestamp: number;
  private seekedTimeout: number;

  private seekStarted: boolean;
  private seekEnded: boolean;

  constructor(stateMachineCallbacks: StateMachineCallbacks, opts: AnalyticsStateMachineOptions) {
    super(stateMachineCallbacks, opts);
    this.pausedTimestamp = null;
    this.seekTimestamp = 0;
    this.seekedTimestamp = 0;
    this.seekedTimeout = 0;
    this.seekStarted = false;
    this.seekEnded = false;
  }

  public getAllStates() {
    return [
      ...Object.keys(State).map((key) => State[key]),
      'FINISH_PLAY_SEEKING',
      'PLAY_SEEK',
      'FINISH_QUALITYCHANGE_PAUSE',
      'FINISH_QUALITYCHANGE',
      'FINISH_QUALITYCHANGE_REBUFFERING',
    ];
  }

  public sourceChange = (config: any, timestamp: number, currentTime?: number) => {
    this.callEvent(Event.MANUAL_SOURCE_CHANGE, {config, currentTime}, timestamp);
  };

  public createStateMachine(opts: AnalyticsStateMachineOptions) {
    return StateMachine.create({
      initial: State.SETUP,
      error: (eventName, from, to, args, errorCode, errorMessage) => {
        logger.error('Error in statemachine: ' + errorMessage);
      },
      events: [
        {name: Event.READY, from: [State.SETUP, State.ERROR], to: State.READY},
        {name: Event.PLAY, from: State.READY, to: State.STARTUP},

        {name: Event.START_BUFFERING, from: State.STARTUP, to: State.STARTUP},
        {name: Event.END_BUFFERING, from: State.STARTUP, to: State.STARTUP},
        {name: Event.VIDEO_CHANGE, from: State.STARTUP, to: State.STARTUP},
        {name: Event.AUDIO_CHANGE, from: State.STARTUP, to: State.STARTUP},
        {name: Event.TIMECHANGED, from: State.STARTUP, to: State.PLAYING},

        {name: Event.TIMECHANGED, from: State.PLAYING, to: State.PLAYING},
        {name: Event.END_BUFFERING, from: State.PLAYING, to: State.PLAYING},
        {name: Event.START_BUFFERING, from: State.PLAYING, to: State.REBUFFERING},
        {name: Event.END_BUFFERING, from: State.REBUFFERING, to: State.PLAYING},
        {name: Event.TIMECHANGED, from: State.REBUFFERING, to: State.REBUFFERING},

        {name: Event.PAUSE, from: State.PLAYING, to: State.PAUSE},
        {name: Event.PAUSE, from: State.REBUFFERING, to: State.PAUSE},
        {name: Event.PLAY, from: State.PAUSE, to: State.PLAYING},

        {name: Event.VIDEO_CHANGE, from: State.PLAYING, to: State.QUALITYCHANGE},
        {name: Event.AUDIO_CHANGE, from: State.PLAYING, to: State.QUALITYCHANGE},
        {name: Event.VIDEO_CHANGE, from: State.QUALITYCHANGE, to: State.QUALITYCHANGE},
        {name: Event.AUDIO_CHANGE, from: State.QUALITYCHANGE, to: State.QUALITYCHANGE},
        {name: 'FINISH_QUALITYCHANGE', from: State.QUALITYCHANGE, to: State.PLAYING},

        {name: Event.VIDEO_CHANGE, from: State.PAUSE, to: State.QUALITYCHANGE_PAUSE},
        {name: Event.AUDIO_CHANGE, from: State.PAUSE, to: State.QUALITYCHANGE_PAUSE},
        {
          name: Event.VIDEO_CHANGE,
          from: State.QUALITYCHANGE_PAUSE,
          to: State.QUALITYCHANGE_PAUSE,
        },
        {
          name: Event.AUDIO_CHANGE,
          from: State.QUALITYCHANGE_PAUSE,
          to: State.QUALITYCHANGE_PAUSE,
        },
        {name: 'FINISH_QUALITYCHANGE_PAUSE', from: State.QUALITYCHANGE_PAUSE, to: State.PAUSE},

        {name: Event.SEEK, from: State.PAUSE, to: State.PAUSED_SEEKING},
        {name: Event.SEEK, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.AUDIO_CHANGE, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.VIDEO_CHANGE, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.START_BUFFERING, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.END_BUFFERING, from: State.PAUSED_SEEKING, to: State.PAUSED_SEEKING},
        {name: Event.SEEKED, from: State.PAUSED_SEEKING, to: State.PAUSE},
        {name: Event.PLAY, from: State.PAUSED_SEEKING, to: State.PLAYING},
        {name: Event.PAUSE, from: State.PAUSED_SEEKING, to: State.PAUSE},

        {name: 'PLAY_SEEK', from: State.PAUSE, to: State.PLAY_SEEKING},
        {name: 'PLAY_SEEK', from: State.PAUSED_SEEKING, to: State.PLAY_SEEKING},
        {name: 'PLAY_SEEK', from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.SEEK, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.AUDIO_CHANGE, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.VIDEO_CHANGE, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.START_BUFFERING, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.END_BUFFERING, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: Event.SEEKED, from: State.PLAY_SEEKING, to: State.PLAY_SEEKING},

        // We are ending the seek
        {name: Event.PLAY, from: State.PLAY_SEEKING, to: State.END_PLAY_SEEKING},

        {name: Event.START_BUFFERING, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.END_BUFFERING, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.SEEKED, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.TIMECHANGED, from: State.END_PLAY_SEEKING, to: State.PLAYING},

        {name: Event.END, from: State.PLAY_SEEKING, to: State.END},
        {name: Event.END, from: State.PAUSED_SEEKING, to: State.END},
        {name: Event.END, from: State.PLAYING, to: State.END},
        {name: Event.END, from: State.PAUSE, to: State.END},
        {name: Event.SEEK, from: State.END, to: State.END},
        {name: Event.SEEKED, from: State.END, to: State.END},
        {name: Event.TIMECHANGED, from: State.END, to: State.END},
        {name: Event.END_BUFFERING, from: State.END, to: State.END},
        {name: Event.START_BUFFERING, from: State.END, to: State.END},
        {name: Event.END, from: State.END, to: State.END},

        {name: Event.PLAY, from: State.END, to: State.STARTUP},

        {name: Event.ERROR, from: this.getAllStates(), to: State.ERROR},

        {name: Event.SEEK, from: State.END_PLAY_SEEKING, to: State.PLAY_SEEKING},
        {name: 'FINISH_PLAY_SEEKING', from: State.END_PLAY_SEEKING, to: State.PLAYING},

        {name: Event.UNLOAD, from: this.getAllStates(), to: State.END},

        {name: Event.START_AD, from: State.PLAYING, to: State.AD},
        {name: Event.END_AD, from: State.AD, to: State.PLAYING},

        {name: Event.MUTE, from: State.READY, to: State.MUTING_READY},
        {name: Event.UN_MUTE, from: State.READY, to: State.MUTING_READY},
        {name: 'FINISH_MUTING', from: State.MUTING_READY, to: State.READY},

        {name: Event.MUTE, from: State.PLAYING, to: State.MUTING_PLAY},
        {name: Event.UN_MUTE, from: State.PLAYING, to: State.MUTING_PLAY},
        {name: 'FINISH_MUTING', from: State.MUTING_PLAY, to: State.PLAYING},

        {name: Event.MUTE, from: State.PAUSE, to: State.MUTING_PAUSE},
        {name: Event.UN_MUTE, from: State.PAUSE, to: State.MUTING_PAUSE},
        {name: 'FINISH_MUTING', from: State.MUTING_PAUSE, to: State.PAUSE},

        {name: Event.START_CAST, from: [State.READY, State.PAUSE], to: State.CASTING},
        {name: Event.PAUSE, from: State.CASTING, to: State.CASTING},
        {name: Event.PLAY, from: State.CASTING, to: State.CASTING},
        {name: Event.TIMECHANGED, from: State.CASTING, to: State.CASTING},
        {name: Event.MUTE, from: State.CASTING, to: State.CASTING},
        {name: Event.SEEK, from: State.CASTING, to: State.CASTING},
        {name: Event.SEEKED, from: State.CASTING, to: State.CASTING},
        {name: Event.END_CAST, from: State.CASTING, to: State.READY},

        {name: Event.SEEK, from: State.READY, to: State.READY},
        {name: Event.SEEKED, from: State.READY, to: State.READY},
        {name: Event.SEEKED, from: State.STARTUP, to: State.STARTUP},

        {name: Event.MANUAL_SOURCE_CHANGE, from: this.getAllStates(), to: State.SOURCE_CHANGING},
        {name: Event.SOURCE_UNLOADED, from: this.getAllStates(), to: State.SOURCE_CHANGING},

        {name: Event.READY, from: State.SOURCE_CHANGING, to: State.READY},

        // {name: Events.SOURCE_LOADED, from: State.SETUP, to: State.SETUP},
        // {name: Events.SOURCE_LOADED, from: State.READY, to: State.READY},

        {name: Event.VIDEO_CHANGE, from: State.REBUFFERING, to: State.QUALITYCHANGE_REBUFFERING},
        {name: Event.AUDIO_CHANGE, from: State.REBUFFERING, to: State.QUALITYCHANGE_REBUFFERING},
        {
          name: Event.VIDEO_CHANGE,
          from: State.QUALITYCHANGE_REBUFFERING,
          to: State.QUALITYCHANGE_REBUFFERING,
        },
        {
          name: Event.AUDIO_CHANGE,
          from: State.QUALITYCHANGE_REBUFFERING,
          to: State.QUALITYCHANGE_REBUFFERING,
        },
        {
          name: 'FINISH_QUALITYCHANGE_REBUFFERING',
          from: State.QUALITYCHANGE_REBUFFERING,
          to: State.REBUFFERING,
        },
        {name: Event.AUDIOTRACK_CHANGED, from: State.PLAYING, to: State.AUDIOTRACK_CHANGING},
        {name: Event.AUDIOTRACK_CHANGED, from: State.PAUSE, to: State.PAUSE},
        {name: Event.AUDIOTRACK_CHANGED, from: State.REBUFFERING, to: State.REBUFFERING},
        {name: Event.AUDIOTRACK_CHANGED, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.AUDIOTRACK_CHANGED, from: State.AUDIOTRACK_CHANGING, to: State.AUDIOTRACK_CHANGING},
        {name: Event.TIMECHANGED, from: State.AUDIOTRACK_CHANGING, to: State.PLAYING},

        {name: Event.SUBTITLE_CHANGE, from: State.PLAYING, to: State.SUBTITLE_CHANGING},
        {name: Event.SUBTITLE_CHANGE, from: State.PAUSE, to: State.PAUSE},
        {name: Event.SUBTITLE_CHANGE, from: State.REBUFFERING, to: State.REBUFFERING},
        {name: Event.SUBTITLE_CHANGE, from: State.END_PLAY_SEEKING, to: State.END_PLAY_SEEKING},
        {name: Event.SUBTITLE_CHANGE, from: State.SUBTITLE_CHANGING, to: State.SUBTITLE_CHANGING},
        {name: Event.TIMECHANGED, from: State.SUBTITLE_CHANGING, to: State.PLAYING},

        {name: Event.CUSTOM_DATA_CHANGE, from: [State.PLAYING, State.PAUSE], to: State.CUSTOMDATACHANGE},
        {name: Event.PLAYING, from: State.CUSTOMDATACHANGE, to: State.PLAYING},
        {name: Event.PAUSE, from: State.CUSTOMDATACHANGE, to: State.PAUSE},
      ],
      callbacks: {
        onpause: (event, from, to, timestamp) => {
          if (from === State.PLAYING) {
            this.pausedTimestamp = timestamp;
          }
        },
        onbeforeevent: (event, from, to, timestamp, eventObject) => {
          if (event === Event.SEEK && from === State.PAUSE) {
            if (!this.seekStarted) {
              this.seekTimestamp = timestamp;
              this.seekStarted = true;
            }
            const elapsedTime = timestamp - this.pausedTimestamp;
            if (elapsedTime < Bitmovin7AnalyticsStateMachine.PAUSE_SEEK_DELAY && elapsedTime > 0) {
              this.stateMachine.PLAY_SEEK(timestamp);
              return false;
            }
          }

          if (event === Event.SEEK) {
            window.clearTimeout(this.seekedTimeout);
          }

          if (event === Event.SEEKED && from === State.PAUSED_SEEKING) {
            this.seekedTimestamp = timestamp;
            this.seekEnded = true;
            this.seekedTimeout = window.setTimeout(() => {
              this.stateMachine.pause(timestamp, eventObject);
            }, Bitmovin7AnalyticsStateMachine.SEEKED_PAUSE_DELAY);
            return false;
          }

          if (from === State.REBUFFERING && to === State.QUALITYCHANGE_REBUFFERING) {
            return false;
          }
        },
        onafterevent: (event, from, to, timestamp) => {
          if (to === State.QUALITYCHANGE_PAUSE) {
            this.stateMachine.FINISH_QUALITYCHANGE_PAUSE(timestamp);
          }
          if (to === State.QUALITYCHANGE) {
            this.stateMachine.FINISH_QUALITYCHANGE(timestamp);
          }
          if (to === State.QUALITYCHANGE_REBUFFERING) {
            this.stateMachine.FINISH_QUALITYCHANGE_REBUFFERING(timestamp);
          }
          if (to === State.MUTING_READY || to === State.MUTING_PLAY || to === State.MUTING_PAUSE) {
            this.stateMachine.FINISH_MUTING(timestamp);
          }
        },
        onenterstate: (
          event: string | undefined,
          from: string | undefined,
          to: string | undefined,
          timestamp: number,
          eventObject: any
        ) => {
          if (from === 'none' && opts.starttime) {
            this.onEnterStateTimestamp = opts.starttime;
          } else {
            this.onEnterStateTimestamp = timestamp || new Date().getTime();
          }

          logger.log('[ENTER] ' + padRight(to, 20) + 'EVENT: ' + padRight(event, 20) + ' from ' + padRight(from, 14));
          if (
            eventObject &&
            to !== State.PAUSED_SEEKING &&
            to !== State.PLAY_SEEKING &&
            to !== State.END_PLAY_SEEKING
          ) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }

          if (event === Event.START_CAST && to === State.CASTING) {
            this.stateMachineCallbacks.startCasting(timestamp, eventObject);
          }

          if (to === State.REBUFFERING) {
            this.startRebufferingHeartbeatInterval();
          }
        },
        onleavestate: (event, from, to, timestamp, eventObject) => {
          if (from === State.REBUFFERING) {
            this.resetRebufferingHelpers();
          }

          if (!timestamp) {
            return;
          }
          this.addStatesToLog(event, from, to, timestamp, eventObject);
          const stateDuration = timestamp - this.onEnterStateTimestamp;

          if (
            eventObject &&
            to !== State.PAUSED_SEEKING &&
            to !== State.PLAY_SEEKING &&
            to !== State.END_PLAY_SEEKING
          ) {
            this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);
          }

          if (event === 'PLAY_SEEK' && from === State.PAUSE) {
            return true;
          }

          const fnName = String(from).toLowerCase();
          if (from === State.END_PLAY_SEEKING || from === State.PAUSED_SEEKING) {
            const seekDuration = this.seekedTimestamp - this.seekTimestamp;
            if (this.seekStarted && this.seekEnded && to !== State.PLAY_SEEKING) {
              this.seekEnded = this.seekStarted = false;
              this.stateMachineCallbacks[fnName](seekDuration, fnName, eventObject);
            }
          } else if (event === Event.UNLOAD) {
            this.stateMachineCallbacks.unload(stateDuration, fnName);
          } else if (from === State.PAUSE && to !== State.PAUSED_SEEKING) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(event);
            this.stateMachineCallbacks.pause(stateDuration, fnName);
          } else {
            const callbackFunction = this.stateMachineCallbacks[fnName];
            if (typeof callbackFunction === 'function') {
              callbackFunction(stateDuration, fnName, eventObject);
            } else {
              logger.error('Could not find callback function for ' + fnName);
            }
          }

          if (
            eventObject &&
            to !== State.PAUSED_SEEKING &&
            to !== State.PLAY_SEEKING &&
            to !== State.END_PLAY_SEEKING
          ) {
            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }

          if (event === Event.VIDEO_CHANGE) {
            this.stateMachineCallbacks.videoChange(eventObject);
          } else if (event === Event.AUDIO_CHANGE) {
            this.stateMachineCallbacks.audioChange(eventObject);
          } else if (event === Event.MUTE) {
            this.stateMachineCallbacks.mute();
          } else if (event === Event.UN_MUTE) {
            this.stateMachineCallbacks.unMute();
          } else if (event === Event.MANUAL_SOURCE_CHANGE) {
            this.stateMachineCallbacks.manualSourceChange(eventObject);
          }
        },
        onseek: (event, from, to, timestamp) => {
          if (!this.seekStarted) {
            this.seekTimestamp = timestamp;
            this.seekStarted = true;
          }
        },
        onseeked: (event, from, to, timestamp) => {
          this.seekedTimestamp = timestamp;
          this.seekEnded = true;
        },
        ontimechanged: (event, from, to, timestamp, eventObject) => {
          const stateDuration = timestamp - this.onEnterStateTimestamp;

          if (stateDuration > 59700) {
            this.stateMachineCallbacks.setVideoTimeEndFromEvent(eventObject);

            this.stateMachineCallbacks.heartbeat(stateDuration, String(from).toLowerCase(), {played: stateDuration});
            this.onEnterStateTimestamp = timestamp;

            this.stateMachineCallbacks.setVideoTimeStartFromEvent(eventObject);
          }
        },
        onplayerError: (event, from, to, timestamp, eventObject) => {
          this.stateMachineCallbacks.error(eventObject);
        },
      },
    });
  }

  public callEvent(eventType: string, eventObject: any, timestamp: number) {
    const exec = this.stateMachine[eventType];

    if (exec) {
      try {
        exec.call(this.stateMachine, timestamp, eventObject);
      } catch (e) {
        logger.error('Exception occured in State Machine callback ' + eventType, exec, eventObject, e);
      }
    } else {
      logger.log('Ignored Event: ' + eventType);
    }
  }

  public addStatesToLog(
    event: string | undefined,
    from: string | undefined,
    to: string | undefined,
    timestamp: number,
    eventObject: any
  ) {
    if (this.enabledDebugging) {
      this.debuggingStates.push(new EventDebugging(event, from, to, timestamp, eventObject));
    }
  }

  public getStates() {
    return this.debuggingStates;
  }

  public setEnabledDebugging(enabled: boolean) {
    this.enabledDebugging = enabled;
  }
}
