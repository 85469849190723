import {HTML5AnalyticsStateMachine} from '../../analyticsStateMachines/HTML5AnalyticsStateMachine';
import {Event} from '../../enums/Event';
import {Player} from '../../enums/Player';
import {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import {DrmPerformanceInfo} from '../../types/DrmPerformanceInfo';
import {PlaybackInfo} from '../../types/PlaybackInfo';
import {InternalAdapter} from './InternalAdapter';
import {InternalAdapterAPI} from './InternalAdapterAPI';

export class CAFv3InternalAdapter extends InternalAdapter implements InternalAdapterAPI {
  private playerManager;
  private mediaInformation;
  private activeAudioTrack;
  private activeTextTracks;
  private currentItem;
  private isSeeking: boolean;

  constructor(protected context: any, opts?: AnalyticsStateMachineOptions) {
    super(opts);
    this.stateMachine = new HTML5AnalyticsStateMachine(this.stateMachineCallbacks, this.opts);
    this.playerManager = context.getPlayerManager();
    this.isSeeking = false;
  }

  public initialize(): void {
    this.register();
  }

  public getPlayerVersion(): string {
    return (window as any).cast && (window as any).cast.framework ? (window as any).cast.framework.VERSION : 'unknown';
  }

  public getPlayerName = () => Player.CHROMECAST_SHAKA;
  public getPlayerTech = () => 'html5';
  public getAutoPlay = () => (this.currentItem && this.currentItem.autoplay ? this.currentItem.autoplay : false);
  public getDrmPerformanceInfo = (): DrmPerformanceInfo | undefined => this.drmPerformanceInfo;

  public sourceChange(config: any, timestamp: number) {
    this.stateMachine.sourceChange(config, timestamp, this.playerManager.getCurrentTimeSec());
  }

  public getCurrentPlaybackInfo(): PlaybackInfo {
    const stats = (this.playerManager as any).getStats();

    const info: PlaybackInfo = {
      isLive: this.mediaInformation ? this.mediaInformation.streamType === 'LIVE' : undefined,
      playerTech: this.getPlayerTech(),
      videoDuration: this.mediaInformation ? this.mediaInformation.duration : undefined,
      videoPlaybackHeight: stats.height,
      videoPlaybackWidth: stats.width,
      audioCodec: this.activeAudioTrack ? this.activeAudioTrack.trackContentType : undefined,
      audioLanguage: this.activeAudioTrack ? this.activeAudioTrack.language : undefined,
      subtitleLanguage: this.activeTextTracks ? this.activeTextTracks.map((t) => t.language).join(',') : undefined,
      droppedFrames: 0, // TODO
    };

    return info;
  }

  public register() {
    this.playerManager.addEventListener('LOADED_METADATA', (event) => {
      this.eventCallback(Event.SOURCE_LOADED, {});
    });

    this.playerManager.addEventListener('PLAYER_LOAD_COMPLETE', (event) => {
      const audioTracksManager = this.playerManager.getAudioTracksManager();
      this.activeAudioTrack = audioTracksManager.getActiveTrack();

      // captions
      const textTracksManager = this.playerManager.getTextTracksManager();
      this.activeTextTracks = textTracksManager.getActiveTracks();

      const queueManager = this.playerManager.getQueueManager();
      this.currentItem = queueManager.getCurrentItem();

      this.mediaInformation = event.media;

      this.eventCallback(Event.READY, {});
    });

    this.playerManager.addEventListener('PLAY', (event) => {
      this.eventCallback(Event.PLAY, {
        currentTime: event.currentMediaTime,
      });
    });

    this.playerManager.addEventListener('PAUSE', (event) => {
      this.onPaused(this.playerManager.getCurrentTimeSec());
    });

    this.playerManager.addEventListener('ERROR', (event) => {
      this.eventCallback(Event.ERROR, {
        currentTime: this.playerManager.getCurrentTimeSec(),
        code: event.detailedErrorCode,
        message: event.reason,
        data: event.error ? JSON.stringify(event.error) : undefined,
      });
    });

    this.playerManager.addEventListener('SEEKING', (event) => {
      this.isSeeking = true;
      this.onPaused(event.currentMediaTime);
      this.eventCallback(Event.SEEK, {
        currentTime: event.currentMediaTime,
      });
    });

    this.playerManager.addEventListener('SEEKED', (event) => {
      this.isSeeking = false;
      this.eventCallback(Event.SEEKED, {
        currentTime: event.currentMediaTime,
      });
    });

    // Fired when the browser is trying to fetch media data,
    // but did not receive a response. The cast.framework.events.EventType.BUFFERING
    // event is implemented consistently across stream types, and should be used instead
    // of 'stalled' when trying to check if the player is buffering.
    this.playerManager.addEventListener('STALLED', (event) => {});

    this.playerManager.addEventListener('BUFFERING', (event) => {
      if (!this.isSeeking && event.isBuffering) {
        this.eventCallback(Event.START_BUFFERING, {
          currentTime: event.currentMediaTime,
        });
      }
    });

    this.playerManager.addEventListener('TIME_UPDATE', (event) => {
      if (!this.isSeeking) {
        this.eventCallback(Event.TIMECHANGED, {
          currentTime: event.currentMediaTime,
        });
      }
    });
  }

  public onPaused(currentTime?: number) {
    this.eventCallback(Event.PAUSE, {
      currentTime,
    });
  }
  protected get currentTime(): number {
    return this.playerManager.getCurrentTimeSec();
  }
}
