import {DeferredLicenseLoadingAdapterAPI} from '../adapters/internal/DeferredLicenseLoadingAdapterAPI';
import {AnalyticsConfig, CollectorConfig} from '../types/AnalyticsConfig';
import {LicensingRequest} from '../types/LicensingRequest';
import {LicenseCall} from '../utils/LicenseCall';
import {ANALYTICS_BACKEND_BASE_URL} from '../utils/Settings';
import {Backend} from './Backend';
import {DebuggingBackend} from './DebuggingBackend';
import {LicenseCheckingBackend} from './LicenseCheckingBackend';
import {LongPlaytimeFilteringBackend} from './LongPlaytimeFilteringBackend';
import {NoOpBackend} from './NoOpBackend';
import {SequenceNumberBackend} from './SequenceNumberBackend';

interface InternalCollectorConfig extends CollectorConfig {
  wrapBackend: (innerBackend: Backend) => Backend;
}

export class BackendFactory {
  public createBackend(
    analyticsConfig: AnalyticsConfig,
    licensingRequest: LicensingRequest,
    adapter: DeferredLicenseLoadingAdapterAPI
  ): Backend {
    if (!this.isEnabled(analyticsConfig)) {
      return new NoOpBackend();
    }
    const inner = this.createInnerBackend(analyticsConfig, licensingRequest, adapter);
    return this.decorateWithDebuggingBackend(analyticsConfig, inner);
  }

  private decorateWithDebuggingBackend(config: AnalyticsConfig, backend: Backend): Backend {
    if (config.debug) {
      return new DebuggingBackend(config, backend);
    }
    return backend;
  }

  private decorateWithBackendFromConfigIfAvailable(analyticsConfig: AnalyticsConfig, innerBackend: Backend): Backend {
    if (
      analyticsConfig !== undefined &&
      analyticsConfig.config !== undefined &&
      (analyticsConfig.config as InternalCollectorConfig).wrapBackend !== undefined
    ) {
      return (analyticsConfig.config as InternalCollectorConfig).wrapBackend(innerBackend);
    }

    return innerBackend;
  }

  private isEnabled(analyticsConfig: AnalyticsConfig): boolean {
    return analyticsConfig.config === undefined || analyticsConfig.config.enabled !== false;
  }

  private createInnerBackend(
    analyticsConfig: AnalyticsConfig,
    licensingRequest: LicensingRequest,
    adapter: DeferredLicenseLoadingAdapterAPI
  ): Backend {
    const backendUrl = (analyticsConfig.config && analyticsConfig.config.backendUrl) || ANALYTICS_BACKEND_BASE_URL;
    let innerBackend: Backend = new LicenseCheckingBackend(licensingRequest, LicenseCall, backendUrl, adapter);
    innerBackend = this.decorateWithBackendFromConfigIfAvailable(analyticsConfig, innerBackend);

    const sequenceNumberAssigningBackend = new SequenceNumberBackend(innerBackend);
    return new LongPlaytimeFilteringBackend(sequenceNumberAssigningBackend);
  }
}
